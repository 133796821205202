import React, { Component, Fragment } from 'react';
import { Skeleton } from 'antd';
import { toast, ToastContainer } from 'react-toastify';

import creApi from '../../services/creService';
import {
  generateViolationTableData,
  uniquekeys
} from '../../services/helperService';
import LocalState from '../../common/localState';
import { getColorLabel } from '../../services/colorService';
import RetainedAllViolations from './retainedAllViolation';
import TableViolation from '../../common/table-violation/tableViolation';
import styles from './dashboardDetails.module.css'

import DashboardDetailsList from './dashboardDetailsList'
import DashboardDetailsGrid from './dashboardDetailsGrid'
import ViolationList from './ViolationList'
import { Popover } from 'antd';
import {
  DownOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const formatSub = (sub) => {
  if (sub === 'BcompliantRetained') return 'BCompliant Retained'
  if (sub === 'totalAll') return 'Total All'
  return sub
}

class DashboardDetails extends Component {
  state =
    this.props.jestData !== undefined
      ? this.props.jestData.state
      : {

        tableData: [],
        filter: {},
        viewType: LocalState.getViewTypeSetting('dashboard', 'list'),
        sortField: {},

        allViolationsData: {},
        dataForTable: [],
        customDashboardData: [],
        doneLoading: false,
        dataForTableLoading: false,
        isAllViolationsLoading: true,
        currentPage: new URLSearchParams(this.props.location.search).get(
          'page'
        )
          ? parseInt(
            new URLSearchParams(this.props.location.search).get('page')
          )
          : 1,
      };
  getData = async (dashboard, sub) => {
    try {
      let apiData;
      apiData = await creApi.getDashboardData(dashboard, sub);

      this.setState({
        doneLoading: true,
        tableData: apiData?.data,
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  getCustomDashboard = async (dashboard, sub) => {
    try {
      const response = await creApi.getDashboardDataCustom(dashboard, sub);
      if (response.data.status) {
        this.setState({
          customDashboardData: response.data.data,
          customDashboardDataName: response.data.propertyName
        });
      } else {
        toast.error(response.data.message);
      }

    } catch (e) {
      toast.error(e.message);
    }
  };

  initilize() {
    this.setState({
      doneLoading: false,
      dataForTableLoading: false,
      isAllViolationsLoading: true,
    })
    this.getData(this.props.match.params.category, this.props.match.params.sub);
    this.getCustomDashboard(this.props.match.params.category, this.props.match.params.sub);
    this.fetchAllViolations();
  }

  componentDidMount() {
    this.initilize()
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.match.params.sub !== this.props.match.params.sub) ||
      (prevProps.match.params.category !== this.props.match.params.category)
    ) {
      this.initilize()
    }

  }

  onClick = (violationsDetails) => {
    let ids = violationsDetails.violationsDetails?.map((v) => v._id) || [];
    const historyState = { ids, from: this.props.location.pathname };
    localStorage.setItem('prev-location', historyState.from);
    const category =
      this.props.match.params.sub === 'BcompliantRetained'
        ? 'BCompliant'
        : violationsDetails.match.category;

    if (this.props.match.params.sub === 'BcompliantRetained') {
      this.props.history.push(
        `/violations/table-retained/${violationsDetails.bin}/${category}/${violationsDetails.match.sub
          ? violationsDetails.match.sub
          : this.props.match.params.sub
            ? this.props.match.params.sub
            : 'Corrections__Open'
        }`,
        historyState
      );
    } else if (this.props.match.params.category === 'Colors') {
      this.props.history.push(
        `/violations/table-colors/${violationsDetails.bin}/${this.props.match.params.category}/${this.props.match.params.sub}`,
        historyState
      );
    } else if (this.state.sub === 'Outstanding Fines') {
      this.props.history.push(
        `/violations/table/${violationsDetails.bin}/${this.state.category}/${this.state.sub}`,
        historyState
      );
    } else {

      this.props.history.push(
        `/violations/table/${violationsDetails.bin}/${category}/${violationsDetails.match.sub
          ? violationsDetails.match.sub
          : 'Corrections__Open'
        }`,
        historyState
      );
    }
  };

  handleBack = () => {
    this.props.history.push('/dashboard');
  };

  switchToggle = async () => {
    const type = this.state.viewType === 'grid' ? 'list' : 'grid';
    await creApi.updateUserPageView({
      page: 'dashboard',
      type,
    });
    this.setState({ viewType: type });
    if (
      this.props.match.params.category === 'Colors' ||
      this.props.match.params.sub === 'BcompliantRetained'
    ) {
      window.location.reload(false);
    }
  };

  onPageChange = (page) => {
    this.setState({ currentPage: page });
    this.props.history.push({
      search: `?page=${page}`,
    });
    this.fetchAllViolations(page);
  };

  handleSort = async (_sortField) => {
    const { tableData, sortField } = this.state;
    const direction =
      typeof sortField[_sortField] != 'undefined' &&
        sortField[_sortField] === -1
        ? 1
        : -1;
    const sort = {
      [_sortField]: direction,
    };
    this.setState({ sortField: sort });
    const unSortedArr = tableData;

    if (_sortField === 'Address') {
      unSortedArr.sort((a, b) => {
        return (
          direction *
          a.address.toLowerCase().localeCompare(b.address.toLowerCase())
        );
      });
    } else if (_sortField === 'VPU') {
      unSortedArr.sort((a, b) => {
        if (direction === -1) {
          return (b.VPU || 0) - (a.VPU || 0);
        }
        return (a.VPU || 0) - (b.VPU || 0);
      });
    } else {
      unSortedArr.sort((a, b) => {
        if (direction === -1) {
          return b.violationsDetails.length - a.violationsDetails.length;
        }
        return a.violationsDetails.length - b.violationsDetails.length;
      });
    }

    this.setState({
      tableData: unSortedArr,
    });
  };

  fetchAllViolations = async (page = this.state.currentPage) => {
    this.setState({
      isAllViolationsLoading: true,
    });
    try {
      let sub = this.props.match.params.sub;
      let category = this.props.match.params.category;
      let sortBy = this.props.match.params.sortBy || '';
      let sortType = parseInt(this.props.match.params.sortType) || '';
      const dashboard = true;

      try {
        const { data } = await creApi.getViolationsForAllBuilding(
          category,
          sub,
          [], // filter
          sortBy, // sortBy
          sortType, // sortType
          null,
          dashboard
        );
        let dataForTable = {};
        let violationData =
          data.violationsData?.[sub]?.map((r) => r.violationData) || [];

        let orgTableData = data.violationsData?.[sub];

        let codes = violationData.map((item) => {
          if (item?.ordernumber) {
            return item.ordernumber.replace(' *', '');
          }
          return null;
        });

        if (codes.length) {
          const { data } = await creApi.getHPDViolationCodesDesc(codes);

          if (data.data) {
            orgTableData.map((item) => {
              data.data.forEach((data) => {
                if (data.code.includes(item.violationData?.ordernumber)) {
                  item.violationData.codeColor = data.colorCode;
                  item.violationData.codeName = data.name;
                }
              });
              return item;
            });
          }
        }

        if (
          'displayName' in data &&
          'violationsData' in data &&
          data.displayName.length
        ) {
          dataForTable = generateViolationTableData(
            data.displayName,
            data.violationsData[sub],
            dashboard,
            sub,
            data.dollarAmountFields
          );
        }
        this.setState({
          isAllViolationsLoading: false,
          allViolationsData: data,
          sub: sub,
          category: category,
          exportViolationData: data,
          dataForTable,
          dataForTableLoading: true,
        });
      } catch (e) {
        toast.error(e.message);
      }
    } catch (e) {
      toast.error(e.message);
      this.setState({
        isAllViolationsLoading: false,
      });
    }
  };

  render() {
    const { viewType, sortField } = this.state;
    const search = new URLSearchParams(this.props.location.search);
    let advancedSearchDefault = null
    if (search.get("adProperty")) {
      let value = search.get("adValue")
      if (value === 'true') {
        value = true
      }
      advancedSearchDefault = {
        [search.get("adProperty")]: [search.get("adValue") === 'true' ? true : value]
      }
    }

    return (
      <Fragment>
        <div className="container-fluid">
          <div className="row">
            <div className={`col-xl-12 custom-violation`}>
              <ToastContainer />
              <div className={`card-header d-flex justify-content-between ${this.props.additional ? 'pt-0' : ''}`}>
                {!this.props.additional && <div className="d-flex">
                  <button
                    className="btn btn-light btn-xs align-self-center back-btn"
                    onClick={this.handleBack}
                    type="button"
                  >
                    <i className="fa fa-angle-left"></i>
                  </button>
                </div>}
                <div className="col-md-9 d-flex">
                
                  <h5 className=" d-flex align-self-center">

                    {this.props.match.params.category}  {this.state.category?.includes('Violations') &&
                    <div className='d-flex align-items-center pb-2 m-1 pointer '>
                      <Popover
                        className='align-self-bottom'
                        placement="bottom"
                        content={
                          <Link to={`/dashboard/${this.props.match.params.category === 'Closed Violations' ? 'Open Violations' : 'Closed Violations'}/${this.props.match.params.sub}`}>
                            {this.props.match.params.category === 'Closed Violations' ? 'Open' : 'Closed'} Violations
                          </Link>}>
                        <DownOutlined style={{ fontSize: '20px' }} />
                      </Popover>
                    </div>
                  }
                      {' / ' +
                      (this.props.match.params.category === 'Colors'
                        ? getColorLabel(this.props.match.params.sub)
                        : formatSub(this.props.match.params.sub))}
                  </h5>
                </div>
                <div className="col-3 col-md-2 align-self-center text-right">
                  <div id="btnContainer">
                    <button
                      className={`btn ${viewType === 'grid' ? 'active' : ''}`}
                      onClick={() => this.switchToggle()}
                    >
                      <i className="fa fa-th-large"></i>
                    </button>
                    <button
                      className={`btn ${viewType === 'list' ? 'active' : ''}`}
                      onClick={() => this.switchToggle()}
                    >
                      <i className="fa fa-bars"></i>
                    </button>
                  </div>
                </div>
              </div>

              {this.state.category?.includes('Violations') &&
                <ViolationList type={this.state.category} />
              }

              {!!this.state.tableData.length && !!this.state.customDashboardData?.length ?
                <>
                  <h3 className={styles.customDashboardHeader}>{this.state.customDashboardDataName} </h3>
                  <div className='d-flex flex-wrap'>
                    {this.state.customDashboardData.map(obj =>
                      <div
                        key={obj.name}
                        className={`d-flex ${styles.customDashboardItem}`}
                      // style={{ borderColor: obj.color }}
                      >
                        <a className="d-flex align-items-center w-100" href='#violation-table-main' onClick={() => {
                          this.props.history.push({
                            pathname: '',
                            search: `?adProperty=${this.state.customDashboardDataName}&adValue=${obj.name}`
                          })

                        }}>

                          <div className={styles.customDashboardItemHeader} style={{ background: obj.color }}>

                            {obj.count}
                          </div>

                          <div
                            className={styles.customDashboardItemText}
                            title={obj.name}
                          >
                            <span className={styles.customDashboardItemTextBlock} style={{ color: obj.color }} >{obj.name.toUpperCase()}</span>
                          </div>

                        </a>
                      </div>

                    )} </div>
                </>
                : null
              }

              {!!this.state.tableData.length && (
                <>
                  {viewType === 'grid' && (
                    <DashboardDetailsGrid
                      doneLoading={this.state.doneLoading}
                      sub={this.props.match.params.sub}
                      category={this.props.match.params.category}
                      onClick={this.onClick}
                      tableData={this.state.tableData}
                    />
                  )}
                  {viewType === 'list' && (
                    <DashboardDetailsList
                      handleSort={this.handleSort}
                      doneLoading={this.state.doneLoading}
                      sub={this.props.match.params.sub}
                      category={this.props.match.params.category}
                      sortField={sortField}
                      onClick={this.onClick}
                      tableData={this.state.tableData}
                    />
                  )}
                </>)
              }

              {this.state.category !== 'Colors' &&
                this.state.category !== 'BCompliant' &&
                this.state.sub !== 'BcompliantRetained' &&
                (this.state.dataForTableLoading ? (
                  !!this.state.dataForTable.columns && <TableViolation
                    advancedSearchDefault={advancedSearchDefault}
                    refreshCustomDashboard={() => this.getCustomDashboard(this.props.match.params.category, this.props.match.params.sub)}
                    multiChangeAction={true}
                    data={{
                      bin: null,
                      dashboard: true,
                      columns: this.state.dataForTable.columns,
                      dataSource: this.state.dataForTable.dataSource,
                      customFieldData: this.state.dataForTable.customFieldData,
                      category: this.state.category,
                      sub: this.state.sub,
                      options: {
                        fixedColumn: uniquekeys.reduce((res, key) => { res[key] = true; return res }, {}),
                        links: uniquekeys.reduce((res, key) => { res[key] = '/violation-details/:record._id'; return res }, {})
                      },
                    }}
                  />
                ) : (
                  <Skeleton active />
                ))}
              {(this.state.category === 'Colors' ||
                this.state.category === 'BCompliant' ||
                this.state.sub === 'BcompliantRetained'
              ) && (
                  <RetainedAllViolations
                    data={this.state.allViolationsData}
                    viewType={viewType}
                    history={this.props.history}
                  />
                )}
            </div>
          </div>
        </div >
        {!this.props.additional && this.state.sub === 'totalAll' &&
          <DashboardDetails
            additional={true}
            location={this.props.location}
            match={{
              params: {
                sub: 'DOB',
                category: 'Outstanding Fines'
              }
            }} />
        }
      </Fragment>
    );
  }
}

export default DashboardDetails;
