import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { SocketContext } from '../../../../context/socket';
import creService from '../../../services/creService';
import { toast } from 'react-toastify';

const SafetyMailingViewFilters = ({ order, selectedFilterData, setSelectedFilterData, onExport }) => {

    const socket = useContext(SocketContext);
    const [loadingPdf, setLoadingPdf] = useState(false)
    const handleChange = (name, value) => {
        setSelectedFilterData(state => ({
            ...state,
            [name]: value
        }));
    };

    const pdfExportHandler = async (letter) => {

        setLoadingPdf(true)

        let baseEndpoint = `/api/safety-mailings/responses/pdf/${order._id}${selectedFilterData?.bins?.length ? '?' + selectedFilterData.bins.map(el => 'bins[]=' + el).join('&') : ''}`
        if (letter) {
            baseEndpoint += '&letter=true'
        }
        try {
            const response = await creService.rawGet(baseEndpoint)
            if (response.data.status) {
                toast.success('pdf generation started')
            } else {
                toast.error(response.data.message)
            }
        } catch (err) {
            toast.error(err.message)
        }


    }

    const pdfDoneHandler = async (data) => {
        setLoadingPdf(false)
        window.open(data.url, '_blank');
    }


    useEffect(() => {

        if (socket) {
            socket.on('safety-mailing-pdf', pdfDoneHandler);
            return () => socket.off('safety-mailing-pdf', pdfDoneHandler);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Fragment>
            <div className="row my-3">
                <div className="col-8">
                    <Typeahead
                        id="filter_bins"
                        multiple
                        placeholder="Buildings"
                        onChange={(selected) => {
                            handleChange('bins', selected.map((b) => b.bin));
                        }}
                        selected={order.buildings.filter((b) => selectedFilterData.bins.includes(b.bin))}
                        labelKey={(option) => {
                            if (option.building?.display_address) {
                                return option.building.display_address;
                            }
                            return option.building?.address;
                        }}
                        options={order.buildings.filter(el => el.building)}
                    />
                </div>
                <div className="col-4 pl-0 d-flex align-items-center justify-content-end">

                    {!!selectedFilterData?.bins?.length &&
                        <button type="button" className="btn btn-sm btn-outline-primary mr-1" onClick={() => pdfExportHandler(true)} disabled={loadingPdf}>
                            {loadingPdf ? 'Loaging...' : 'Generate DOH Letter'}
                        </button>
                    }
                    <button type="button" className="btn btn-sm btn-primary mr-1" onClick={onExport}>Export to CSV</button>
                    <button type="button" className="btn btn-sm btn-primary " onClick={() => pdfExportHandler()} disabled={loadingPdf}>{loadingPdf ? 'Loaging...' : 'Export to PDF'}</button>
                </div>
            </div>
        </Fragment>
    );
}

export default SafetyMailingViewFilters;
