import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from "react-router";

import Block from './block'
import creService from '../../services/creService';

const ViolationsBlocks = [
  'DOB',
  'DEP',
  'SWO',
  'VO',
  'HPD',
  'FDNY',
  'ECB-DOB',

  'DOT',
  'DSNY',
  'Hearings',
  'DOH',
  'V.Orders',
  'Corrections',
  'HPD-Cert',
  // 'BcompliantRetained',
]


const ViolationList = ({ type }) => {

  const history = useHistory();


  const [data, setData] = useState({})


  useEffect(() => {
    setData(ViolationsBlocks.reduce((ac, a) => { ac[a] = { total: '' }; return ac }, {}))
    creService.getDashboardData(type).then((res) => {
      const data = ViolationsBlocks.reduce((ac, a) => {
        ac[a] = {
          total: res.data[a]?.totalAllTime,
          label: a === 'VO' ? a : res.data[a]?.label
        }
        return ac
      }, {})
      setData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  function dashboardDetails(sub) {
    history.push(`/dashboard/${type}/${sub}`);
  }

  return (
    <Fragment>
      <div className='d-flex flex-wrap justify-content-center'>
        {ViolationsBlocks.map(el =>
          <Fragment key={el}>
            <Block
              sub={el}
              data={data}
              dashboardDetails={dashboardDetails}
              type='Open Violations'
            />

          </Fragment>
        )}
      </div>
    </Fragment>
  );
};



export default ViolationList;
