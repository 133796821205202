import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, } from 'reactstrap';
import { AutoComplete, Switch } from "antd";
import { toast } from 'react-toastify';
import creService from '../../services/creService';
import ReactLoading from "react-loading";
import { useLocation, useHistory } from "react-router-dom";
import Breadcrumb from '../../common/breadcrumb';

const ExcludedRespondent = (props) => {

    const location = useLocation();

    const history = useHistory();

    const queryParams = {
        action: new URLSearchParams(location?.search).get('action'),
        respondentName: new URLSearchParams(location?.search).get('respondentName'),
    };

    const [excludedRepondents, setExcludedRepondents] = useState(props.jestData !== undefined ? props.jestData.data : []);
    const [includedRepondents, setIncludedRepondents] = useState([]);
    const [showModal, setShowModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [isExcluding, setIsExcluding] = useState(false);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [repondents, setRepondents] = useState([]);

    const [currentRespondent, setCurrentRespondent] = useState({ hide: true });
    const [mode, setMode] = useState('Excluded');

    const filterOptions = (text) => {
        setFilteredOptions(repondents.filter(el => el.value.toLowerCase().includes(text.toLowerCase())))
    }
    const toggleExcludeModal = () => setShowModal(!showModal);
    const switchInfoToggle = async () => {
        const newmode = mode === 'Excluded' ? 'Included' : 'Excluded'
        setIsLoading(true);
        const { data } = await creService.setRespondentsMode(newmode);
        if (data.status) {
            toast.success(`Respondents mode switched to ${newmode}`);
        } else {
            toast.error(data.message);
        }
        setIsLoading(false);
        setMode(mode === 'Excluded' ? 'Included' : 'Excluded')
    }
    const onRespondent = async () => {
        if (!currentRespondent.respondentName) return
        setIsExcluding(true);
        const { data } = await creService.updateCreateRepondent(currentRespondent, mode);
        if (data.status) {
            toast.success(data.message);
            getRepondents();
            setShowModal(false);
        } else {
            if (data.errors) {
                data.errors.map((error) => toast.error(error));
            } else {
                toast.error(data.message);
            }
        }
        history.push('/settings/excluded-respondents');
        setIsExcluding(false);
    }

    const getRepondents = async () => {
        setIsLoading(true);
        const { data } = await creService.getRespondents();
        if (data.status) {
            setExcludedRepondents(data.excludedRepondets || []);
            setIncludedRepondents(data.includedRespondents || []);
            setMode(data.mode)
        } else {
            toast.error(data.message);
        }
        setIsLoading(false);
    }

    const removeRespondent = async (respondentName) => {
        const { data } = await creService.removeRepondent(respondentName, mode)
        if (data.status) {
            toast.success(data.message);
            getRepondents();
        } else {
            if (data.errors) {
                data.errors.map((error) => toast.error(error));
            } else {
                toast.error(data.message);
            }
        }
        history.push('/settings/excluded-respondents');
    }

    const getAllRepondets = async () => {
        const { data } = await creService.getAllRepondets()
        if (data.status) {
            setRepondents(data.data.map(el => ({ value: el, label: el })))
        } else {
            if (data.errors) {
                data.errors.map((error) => toast.error(error));
            } else {
                toast.error(data.message);
            }
        }

    }

    useEffect(() => {
        getRepondents();
        getAllRepondets();
    }, [])

    useEffect(() => {

        if (queryParams.action && queryParams.respondentName) {
            if (queryParams.action === 'add') {
                setCurrentRespondent(excludedRepondents.find(el => el.respondentName === queryParams.respondentName) || { respondentName: queryParams.respondentName })
                setShowModal(true);
            }
            if (queryParams.action === 'remove') {
                removeRespondent(queryParams.respondentName);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [excludedRepondents])
    const respondents = mode === 'Excluded' ? excludedRepondents : includedRepondents
    return (
        <>
            {props.jestData === undefined && <Breadcrumb title={`${mode} Respondents`} parent="Home" />}

            <div className="container-fluid">


                <Button outline={mode !== 'Excluded'} className='rounded-0' onClick={switchInfoToggle} >Excluded Mode</Button>
                <Button outline={mode === 'Excluded'} className='rounded-0' onClick={switchInfoToggle} >Included Mode</Button>


                <div className="table-responsive">
                    <table className="table table-borderless">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Respondent Name</th>
                                <th scope="col">Hide</th>
                                <th scope="col">Added At</th>
                                <th scope="col">Added By</th>
                                <th scope="col" >Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {!isLoading &&
                                respondents.length > 0 &&
                                respondents.map((respondent, index) => {
                                    return (
                                        <tr key={respondent.respondentName} >
                                            <th scope="row">{index + 1}</th>
                                            <td>{respondent.respondentName}</td>
                                            <td>{mode === 'Included' ? 'N/A' : (respondent.hide ? 'Yes' : 'No')}</td>
                                            <td>{moment(respondent.addedAt).format('MM/DD/YYYY hh:MM A')}</td>
                                            <td>{respondent.addedBy?.fullName || 'N/A'}</td>
                                            <td >
                                                <button className="btn btn-warning btn-xs mr-2" onClick={() => { setCurrentRespondent(respondent); toggleExcludeModal() }}>Edit</button>
                                                <button className="btn btn-danger btn-xs" onClick={() => removeRespondent(respondent.respondentName)}>Remove</button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            {!isLoading && respondents.length <= 0 && (
                                <tr>
                                    <td colSpan={5}>
                                        No respondents are {mode} yet.
                                    </td>
                                </tr>
                            )}
                            {isLoading && (
                                <tr>
                                    <td colSpan={5}>
                                        <ReactLoading type={"cylon"} color={"grey"} />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
                <Button onClick={toggleExcludeModal} className="btn btn-primary mt-4">
                    <i className="fa fa-plus" /> Add New Respondent Name
                </Button>
                <Modal isOpen={showModal} toggle={toggleExcludeModal} size="lg" onClosed={() => setCurrentRespondent({ hide: true })}>
                    <ModalHeader toggle={toggleExcludeModal}>Respondent</ModalHeader>
                    <ModalBody>
                        <Form method="POST" onSubmit={(event) => {
                            event.preventDefault();
                            onRespondent()
                        }}>
                            <FormGroup>
                                <Label for="excludeRespondentName">Respondent Name <sup className="text-danger">*</sup></Label>
                                <AutoComplete
                                    className='ml-2'
                                    options={filteredOptions}
                                    style={{ width: 400 }}
                                    value={currentRespondent.respondentName}
                                    onChange={(value) => setCurrentRespondent({ ...currentRespondent, respondentName: value })}
                                    onSearch={filterOptions}
                                    placeholder="Respondent Name"
                                />


                            </FormGroup>
                            <FormGroup
                                check
                                inline
                            >

                                <Input type="checkbox" checked={currentRespondent[mode === 'Excluded' ? 'hide' : 'partialSearch']}
                                    onChange={(e) => setCurrentRespondent({
                                        ...currentRespondent,
                                        [mode === 'Excluded' ? 'hide' : 'partialSearch']:
                                            !currentRespondent[mode === 'Excluded' ? 'hide' : 'partialSearch']
                                    })} />
                                <Label check >
                                    <strong>{mode === 'Excluded' ? 'Hide Violations' : 'Partial Match'}</strong>
                                </Label>
                            </FormGroup>

                            <div className='mt-2'><Button type="submit" disabled={isExcluding}>{isExcluding ? 'Saving...' : 'Save'}</Button></div>

                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        </>
    );
}

export default ExcludedRespondent;
