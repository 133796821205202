import {
  getCurrentUser,
  permissionsKey,
  servicesAccessKey,
} from '../services/authService';

const defaultAvailableServices = [
  'violations',
  'snapshot',
  'colors',
  'workOrders',
  'permits',
  'calendar',
  'complianceDue',
];
const defaultAvailableServicesSubUser = [
  'permits',
  'calendar',
  'complianceDue',
]; // 'safetyMailing', 'custom-compliance'

const serviceAccess = (serviceName, servicesAccess, serviceKey) => {
  // violations, snapshot - general service that all users have access by default
  if (
    defaultAvailableServices.includes(serviceName) ||
    (servicesAccess[serviceName]?.enabled &&
      (serviceKey ? servicesAccess[serviceName]?.[serviceKey] : true))
  ) {
    return true;
  }
  return false;
};

const isGranted = (serviceName, action) => {
  const permissions = JSON.parse(localStorage.getItem(permissionsKey) || '{}');
  if (!serviceName || !action) {
    return true;
  }

  if (permissions[serviceName] && permissions[serviceName].includes(action)) {
    return true;
  }

  if (typeof action === 'object') {
    // if multiple actions provided
    for (let i = 0; i < action.length; i += 1) {
      if (
        permissions[serviceName] &&
        permissions[serviceName].indexOf(action[+i]) !== -1
      ) {
        return true;
      }
    }
  }
  return false;
};
const PhiladelphiaDisabledItems = [
  'permits',
  'calendar',
  // 'custom-compliance',
  'compliance-full-access-guard',
  'complianceDue',
];
function Guard(props) {
  if (!props.allow) props.allow = () => true;

  const servicesAccess = JSON.parse(
    localStorage.getItem(servicesAccessKey) || '{}'
  );
  const user = getCurrentUser();
  const isMasterClient = user?._id === servicesAccess.masterClientId;

  if (
    user?.locationEnv === 'Philadelphia' &&
    (PhiladelphiaDisabledItems.includes(props.serviceName) ||
      PhiladelphiaDisabledItems.includes(props.action))
  ) {
    return props?.deny?.() || null;
  }

  if (
    // check service access
    serviceAccess(props.serviceName, servicesAccess, props.serviceKey) &&
    // permission check for sub users: master client have all permissions by default
    (isMasterClient ||
      defaultAvailableServicesSubUser.includes(props.serviceName) ||
      isGranted(props.serviceName, props.action))
  ) {
    return props.allow();
  } else {
    return props?.deny?.() || null;
  }
}

export default Guard;
